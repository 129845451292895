import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import axios from "axios";

const KnowledgeBase = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [scrapedData, setScrapedData] = useState([]); // State to store fetched data
  const [selectedResource, setSelectedResource] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Fetch scraped data from the backend API
  useEffect(() => {
    const fetchScrapedData = async () => {
      try {
        // const response = await fetch("/api/knowledge/list"); // Replace with your API endpoint
        // const response = await axios.get("/api/knowledge/list");
        const response = await axios.get("/api/knowledgelist", {
          withCredentials: true,
        });
        console.log("response: ", response);
        // if (!response.ok) {
        //   throw new Error("Failed to fetch scraped data");
        // }
        console.log("response.data: ", response.data);
        // const data = await response.json();
        const data = await response.data;
        setScrapedData(data); // Update state with fetched data
      } catch (error) {
        console.error("Error fetching scraped data:", error);
      }
    };

    fetchScrapedData();
  }, []); // Run once on component mount

  // Handle delete button click
  const handleDeleteClick = (resource) => {
    setSelectedResource(resource);
    setShowConfirmation(true);
  };

  // Handle confirmation dialog
  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/api/knowledge/del/${selectedResource._id}`);
      setScrapedData(scrapedData.filter((r) => r._id !== selectedResource._id));
      setShowConfirmation(false);
    } catch (error) {
      console.error("Error deleting resource:", error);
    }
  };

  // Cancel deletion
  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  return (
    <main className="flex">
      <Sidebar title="Knowledge Base" />
      <div className="main-container">
        <Header />
        <div className="knowledgebase">
          {/* Header */}
          <div className="knowledgebase__header">
            <button className="knowledgebase__header-button active">
              Knowledge Base
            </button>
            {/* <button className="knowledgebase__header-button">Drafts</button> */}
            <button className="knowledgebase__header-button">
              <NavLink activeclassname="active" to="/knowledgeadd">
                Add Knowledge
              </NavLink>
            </button>
            {/* <button className="knowledgebase__header-button">
              <NavLink activeclassname="active" to="/knowledgeimport">
                Import Data
              </NavLink>
            </button> */}
          </div>

          {/* Tabs Navigation */}
          <div className="knowledgebase__tabs">
            <button
              className={
                activeTab === "all"
                  ? "knowledgebase__tab active"
                  : "knowledgebase__tab"
              }
              onClick={() => setActiveTab("all")}
            >
              All Data
            </button>
            <button
              className={
                activeTab === "pending"
                  ? "knowledgebase__tab active"
                  : "knowledgebase__tab"
              }
              onClick={() => setActiveTab("pending")}
            >
              Pending Approval
            </button>
          </div>

          {/* Cards Section */}
          <div className="knowledgebase__cards">
            {scrapedData.length > 0 ? (
              scrapedData.map((item, index) => (
                <div key={index} className="knowledgebase__card">
                  <div className="knowledgebase__card-left">
                    <div className="knowledgebase__image-container">
                      <NavLink
                        to={`/knowledgeedit/${item._id}`} // Replace dataID with the actual ID you want to pass
                        title="View"
                      >
                        <img
                          className="knowledgebase__main-image"
                          src={item.images[0]} // Use the first image as the main image
                          alt={item.title}
                        />
                      </NavLink>
                      <div className="knowledgebase__thumbnails">
                        {item.images.slice(1, 4).map((thumbnail, idx) => (
                          <img
                            key={idx}
                            className="knowledgebase__thumbnail"
                            src={thumbnail}
                            alt={`thumbnail ${idx + 1}`}
                          />
                        ))}
                      </div>
                    </div>
                    <a href="#" className="knowledgebase__view-more">
                      View more images
                    </a>
                  </div>
                  <div className="knowledgebase__card-right">
                    <h2 className="knowledgebase__title">{item.title}</h2>
                    <p className="knowledgebase__subtitle">
                      {item.propertyType}
                    </p>
                    <p className="knowledgebase__description">
                      {item.description}
                    </p>
                    <div className="knowledgebase__info">
                      <p>
                        <strong>Price:</strong> {item.currency} {item.price}
                      </p>
                      <p>
                        <strong>Amenities:</strong> {item.amenities.join(", ")}
                      </p>
                    </div>
                    <div className="knowledgebase__price-section">
                      <span className="price">
                        {item.currency} {item.price}
                      </span>
                      <span className="knowledgebase__property-id">
                        <a
                          href={item.webUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Property ID: 21224
                        </a>
                      </span>
                    </div>
                    <div className="knowledgebase__actions">
                      <a
                        href="#"
                        className="knowledgebase__icon-button"
                        title="Share"
                      >
                        <img src="assets/images/share.svg" alt="Share" />
                      </a>
                      <NavLink
                        to={`/knowledgeedit/${item._id}`} // Replace dataID with the actual ID you want to pass
                        className="knowledgebase__icon-button"
                        title="Edit"
                      >
                        <img src="assets/images/edit.svg" alt="Edit" />
                      </NavLink>
                      <a
                        href="#"
                        className="knowledgebase__icon-button"
                        title="Delete"
                        onClick={() => handleDeleteClick(item)}
                      >
                        <img src="assets/images/delete.svg" alt="Delete" />
                      </a>
                      <button className="knowledgebase__marketplace-button">
                        Post on Marketplace
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No data available.</p>
            )}
          </div>
        </div>
        {showConfirmation && (
          <div className="knowledgebase__confirmation-dialog">
            <p>Are you sure you want to delete this property?</p>
            <button onClick={handleConfirmDelete}>Yes</button>
            <button onClick={handleCancelDelete}>No</button>
          </div>
        )}
      </div>
    </main>
  );
};

export default KnowledgeBase;
