import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import axios from "axios";

const KnowledgeImport = () => {
  const [url, setUrl] = useState("");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [scrapedConfig, setScrapedConfig] = useState("");

  useEffect(() => {
    const fetchScrapedconfig = async () => {
      try {
        const response = await axios.get("/api/knowledge/scrapedconfig", {
          withCredentials: true,
        });
        console.log("response: ", response);
        console.log("response.data: ", response.data);
        // const data = await response.json();
        const respData = await response.data;
        setScrapedConfig(respData); // Update state with fetched data
      } catch (error) {
        console.error("Error fetching scraped data:", error);
      }
    };

    fetchScrapedconfig();
  }, []); // Run once on component mount

  const [sections, setSections] = useState({
    unitTypes: false,
    developerDetails: false,
    keyInfo: false,
    projectTimeline: false,
  });

  const toggleSection = (section) => {
    setSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };
  const descValue =
    "Burj Khalifa View | Tallest Residential Tower \n\nApartment for sale in Burj Binghatti Jacob & Co, Business Bay \n\nThe luxurious and tallest residential tower, Burj Binghatti at Business Bay, Dubai, presents luxury 2 & 3 bedroom Suites & Penthouses developed by Binghatti Developers. This one-of-a-kind diamond-shaped at the peak of the tower is reminiscent of an actual crown designed by Jacob & Co, which will have more than 112-storey and provide residents an iconic way of life. \n\nProperty Details: \n* Sapphire Villa \n* Luxury 2-Bedroom \n* 3-Bathroom \n* Close Kitchen. \n* Private Infinity Pool \n* Outside Lounge and Pool Decks \n* Size: 3,332.94 sq. ft \n\nFinest Materials and Finishes: \n•Luxurious and high-end crafted 2 & 3 bedroom Suites & Penthouses \n• Architecture by world-famous Jacob & Co \n• Finest designs and décor with awe-inspiring views \n• Ultimate lifestyle nestled with premium amenities \n\nBrimming with radical and profound ideas, breaking new ground in high-end architectural andhorological spheres, Binghatti and Jacob & Co unite to transcend boundaries never believed possible an astounding architectural and design luxury feat the world has never seen before.";

  const handleScrape = async () => {
    if (!url) {
      setError("Please enter a URL");
      return;
    }
    console.log("url :", url);

    setLoading(true);
    setError("");
    setData();

    try {
      const response = await axios.post("/api/knowledge/scrape", {
        // withCredentials: true,
        url,
      });
      setData(response.data);
      console.log("data: ", data);
    } catch (err) {
      setError(
        err.response?.data?.error ||
          "Failed to scrape the website. Please check the URL and try again."
      );
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="flex">
      <Sidebar title="Knowledge Base" />
      <div className="main-container">
        <Header />
        <div className="knowledgeadd">
          {/* Header */}
          <div className="knowledgeadd__header">
            <button className="knowledgeadd__header-button">
              <NavLink activeclassname="active" to="/knowledgebase">
                Knowledge Base
              </NavLink>
            </button>
            <button className="knowledgeadd__header-button">Drafts</button>
            <button className="knowledgeadd__header-button">
              <NavLink activeclassname="active" to="/knowledgeadd">
                Add Knowledge
              </NavLink>
            </button>
            <button className="knowledgeadd__header-button active">
              Import Data
            </button>
          </div>
          <div className="import-container">
            <div className="import-box">
              <h3 className="import-title">Import Listings</h3>
              <div className="import-row">
                <span className="link-label">Link</span>
                <input
                  type="text"
                  className="link-input"
                  placeholder="Enter website URL"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
                <button
                  className="import-button"
                  onClick={handleScrape}
                  disabled={loading}
                >
                  {loading ? "Importing..." : "Import"}
                </button>
              </div>
              {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
          </div>

          <div className="scraped-data">
            {data && (
              <div>
                <div
                  // key={pageIndex}
                  key={data._id}
                  className="scraped-data"
                  // style={{
                  //   marginBottom: "20px",
                  //   borderBottom: "1px solid #ccc",
                  //   paddingBottom: "20px",
                  // }}
                >
                  {/* Header Bar*/}
                  <div className="knowledgeadd__headerbar">Project Details</div>

                  {/* Image Gallery*/}
                  <div className="knowledgeadd__scraped-img">
                    {data && data.images && data.images.length > 0 ? (
                      data.images.map((img, index) => (
                        <img
                          key={index}
                          src={img}
                          alt={`Scraped ${index}`}
                          // style={{
                          //   width: "200px",
                          //   margin: "10px",
                          //   border: "1px solid #ccc",
                          // }}
                        />
                      ))
                    ) : (
                      <p>No images available</p>
                    )}
                  </div>

                  {/* select menu */}
                  <div className="knowledgeadd__select-container">
                    <select className="knowledgeadd__custom-select">
                      <option>Listing Type</option>
                    </select>
                    <select className="knowledgeadd__custom-select">
                      <option>Property Type</option>
                    </select>
                    <select className="knowledgeadd__custom-select">
                      <option>Select City</option>
                    </select>
                  </div>

                  {/* form fields */}
                  <div className="knowledgeadd__form-fields">
                    <label>Project Title*</label>
                    <input type="text" value={data.title} />
                    <label>Project Type*</label>
                    <input type="text" value={data.propertyType} />
                    <label className="knowledgeadd__textarea-label">
                      Project Description*
                    </label>
                    <textarea
                      className="knowledgeadd__textarea-input"
                      value={data.description}
                    />
                    <label className="knowledgeadd__textarea-label">
                      Amenities
                    </label>
                    <textarea
                      className="knowledgeadd__textarea-input"
                      value={data.amenities}
                    />
                    <label>Starting Price*</label>
                    <input type="text" value={data.price} />
                    <label>Currency*</label>
                    <input type="text" value={data.currency} />
                    <label>Project Website</label>
                    <input type="text" value={data.webUrl} />
                  </div>

                  {/* Save Buttons */}
                  <div className="knowledgeadd__buttons">
                    <button className="knowledgeadd__cancel">Cancel</button>
                    <button className="knowledgeadd__draft">
                      Save to Draft
                    </button>
                    <button className="knowledgeadd__submit">
                      Save & Submit
                    </button>
                  </div>

                  {/* Scraped View*/}
                  {/* <h2>Scraped Data</h2>
                  <h3>Page {data._id + 1}</h3>
                  <p>
                    <strong>Title:</strong> {data.title}
                  </p>
                  <p>
                    <strong>Property Type:</strong> {data.propertyType}
                  </p>
                  <p>
                    <strong>Description:</strong> {data.description}
                  </p>
                  <p>
                    <strong>Currency:</strong> {data.currency}
                  </p>
                  <p>
                    <strong>Price:</strong> {data.price}
                  </p>
                  <div>
                    <p>
                      <strong>Images:</strong>
                    </p>
                    <div className="scraped-img">
                      {data && data.images && data.images.length > 0 ? (
                        data.images.map((img, index) => (
                          <img
                            key={index}
                            src={img}
                            alt={`Scraped ${index}`}
                            // style={{
                            //   width: "200px",
                            //   margin: "10px",
                            //   border: "1px solid #ccc",
                            // }}
                          />
                        ))
                      ) : (
                        <p>No images available</p>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
            )}
            <div className="knowledgeadd__form-fields">
              <p>
                <strong>Supported Websites:</strong>
                <ul>
                  {scrapedConfig && scrapedConfig.length > 0 ? (
                    scrapedConfig.map((dataConfig, index) => (
                      <li>{dataConfig.domain}</li>
                    ))
                  ) : (
                    <li>No supported websites available</li>
                  )}
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default KnowledgeImport;
