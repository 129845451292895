import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import KnowledgeDetail from "../components/KnowledgeDetail";
import axios from "axios";

const KnowledgeEdit = () => {
  const { dataid } = useParams();
  // const dataid = "67a76a6215194a1851d4be6d";
  const [url, setUrl] = useState("");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [title, setTitle] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([""]);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchKnowledgeDetail = async () => {
      try {
        console.log("useEffect dataid: ", dataid);
        const response = await axios.get(`/api/knowledgedetail/${dataid}`);
        console.log("Knowledge Detail:", response.data);
        setData(response.data);
        // Handle the response data as needed
      } catch (error) {
        setError(error.message);
      }
    };

    if (dataid) {
      fetchKnowledgeDetail();
    }
  }, [dataid]); // Dependency array includes dataid

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <main className="flex">
      <Sidebar title="Knowledge Base" />
      <div className="main-container">
        <Header />
        <div className="knowledgeadd">
          {/* Header */}
          <div className="knowledgebase__header">
            <button className="knowledgebase__header-button active">
              Knowledge Base
            </button>
            <button className="knowledgebase__header-button">Drafts</button>
            <button className="knowledgebase__header-button">
              <NavLink activeclassname="active" to="/knowledgeadd">
                Add Knowledge
              </NavLink>
            </button>
            <button className="knowledgebase__header-button">
              <NavLink activeclassname="active" to="/knowledgeimport">
                Import Data
              </NavLink>
            </button>
          </div>
          <KnowledgeDetail />
        </div>
      </div>
    </main>
  );
};

export default KnowledgeEdit;
