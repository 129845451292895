import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import KnowledgeDetail from "../components/KnowledgeDetail";
import axios from "axios";

const KnowledgeAdd = () => {
  const { dataid } = useParams();
  // const dataid = "67a76a6215194a1851d4be6d";
  const [url, setUrl] = useState("");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [title, setTitle] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([""]);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <main className="flex">
      <Sidebar title="Knowledge Base" />
      <div className="main-container">
        <Header />
        <div className="knowledgeadd">
          {/* Header */}
          <div className="knowledgebase__header">
            <button className="knowledgebase__header-button">
              <NavLink activeclassname="active" to="/knowledgebase">
                Knowledge Base
              </NavLink>
            </button>
            {/* <button className="knowledgebase__header-button">Drafts</button> */}
            <button className="knowledgebase__header-button active">
              Add Knowledge
            </button>
            {/* <button className="knowledgebase__header-button">
              <NavLink activeclassname="active" to="/knowledgeimport">
                Import Data
              </NavLink>
            </button> */}
          </div>
          <KnowledgeDetail isNew="true" />
        </div>
      </div>
    </main>
  );
};

export default KnowledgeAdd;
