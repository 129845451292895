import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom"; // For navigation
import axios from "axios";

const KnowledgeDetail = ({ isNew }) => {
  const navigate = useNavigate(); // For navigating to the main menu
  const { dataid } = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [imgArr, setImgArr] = useState("");

  useEffect(() => {
    const fetchKnowledgeDetail = async () => {
      try {
        console.log("useEffect dataid: ", dataid);
        const response = await axios.get(`/api/knowledgedetail/${dataid}`);
        console.log("Knowledge Detail:", response.data);
        setData(response.data);
        setImgArr(
          Array.isArray(response.data.images)
            ? response.data.images.join("\n")
            : ""
        );
        // Handle the response data as needed
      } catch (error) {
        setError(error.message);
      }
    };

    const emptyData = async () => {
      setData({
        title: "",
        propertyType: "",
        description: "",
        currency: "",
        price: "",
        amenities: "",
        webUrl: "",
        images: [],
      });
    };

    if (isNew === "true") {
      emptyData();
    } else {
      if (dataid) {
        fetchKnowledgeDetail();
      }
    }
  }, [dataid]); // Dependency array includes dataid

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }

  if (!data) {
    return <p>Loading...</p>;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  // Handle changes in the textarea for image URLs
  const handleImageUrlsChange = (e) => {
    // setImageUrls(e.target.value);
    // Split the textarea value by new lines to create an array of image URLs
    // const name = e.target.name;
    const value = e.target.value
      .split("\n")
      .map((url) => url.trim())
      .filter((url) => url);
    setData({ ...data, images: value });
    setImgArr(e.target.value);
    console.log("e.target.value: ", e.target.value);
  };

  const handleSubmit = async (e) => {
    const value = imgArr
      .split("\n")
      .map((url) => url.trim())
      .filter((url) => url);
    console.log("imgArr: ", imgArr);
    console.log("value: ", value);

    let formData = { ...data, images: value };

    console.log("handleSubmit process");
    console.log("formData: ", formData);

    e.preventDefault();
    setLoading(true);

    try {
      if (isNew === "true") {
        const response = await axios.post("/api/knowledge/new", formData);
        if (response.data.message === "Property save successfully") {
          setShowSuccessDialog(true); // Show success dialog
          setShowErrorDialog(false); // Hide error dialog
        }
      } else {
        const response = await axios.post("/api/knowledge/update", formData);
        if (response.data.message === "Property update successfully") {
          setShowSuccessDialog(true); // Show success dialog
          setShowErrorDialog(false); // Hide error dialog
        }
      }
    } catch (error) {
      console.error("Error saving property:", error);
      setErrorMessage(
        error.response?.data?.message || "An unexpected error occurred."
      );
      setShowErrorDialog(true); // Show error dialog
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="knowledgeadd__scraped-data">
      {/* Error Dialog */}
      {showErrorDialog && (
        <div className="knowledgeadd__error-dialog">
          <p>{errorMessage}</p>
          <button onClick={() => setShowErrorDialog(false)}>OK</button>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        {data && (
          <div>
            <div key={data._id} className="scraped-data">
              {/* Header Bar*/}
              <div className="knowledgeadd__headerbar">Project Details</div>

              {/* Image Gallery*/}
              <div className="knowledgeadd__scraped-img">
                {data && data.images && data.images.length > 0 ? (
                  data.images.map((img, index) => (
                    <img key={index} src={img} alt={img} />
                  ))
                ) : (
                  <p>No images available</p>
                )}
              </div>

              {/* select menu */}
              <div className="knowledgeadd__select-container">
                <select className="knowledgeadd__custom-select">
                  <option>Listing Type</option>
                </select>
                <select className="knowledgeadd__custom-select">
                  <option>Property Type</option>
                </select>
                <select className="knowledgeadd__custom-select">
                  <option>Select City</option>
                </select>
              </div>

              {/* form fields */}
              <div className="knowledgeadd__form-fields">
                <label>Project Title*</label>
                <input
                  type="text"
                  name="title"
                  value={data.title}
                  onChange={handleChange}
                  required
                />
                <label>Project Type*</label>
                <input
                  type="text"
                  name="propertyType"
                  value={data.propertyType}
                  onChange={handleChange}
                />
                <label className="knowledgeadd__textarea-label">
                  Project Description*
                </label>
                <textarea
                  name="description"
                  className="knowledgeadd__textarea-input"
                  value={data.description}
                  onChange={handleChange}
                />
                <label className="knowledgeadd__textarea-label">
                  Amenities
                </label>
                <textarea
                  name="amenities"
                  className="knowledgeadd__textarea-input"
                  value={data.amenities}
                  onChange={handleChange}
                />
                <label>Starting Price*</label>
                <input
                  type="text"
                  name="price"
                  value={data.price}
                  onChange={handleChange}
                />
                <label>Currency*</label>
                <input
                  type="text"
                  name="currency"
                  value={data.currency}
                  onChange={handleChange}
                />
                <label>Project Website</label>
                <input
                  type="text"
                  name="webUrl"
                  value={data.webUrl}
                  onChange={handleChange}
                />
                <label label className="knowledgeadd__textarea-label">
                  Images (one URL per line):
                </label>
                <textarea
                  className="knowledgeadd__textarea-input-img"
                  name="images"
                  value={imgArr}
                  // value={Array.isArray(data.images) ? data.images.join("\n") : ""}
                  onChange={handleImageUrlsChange}
                  placeholder="Enter image URLs, one per line"
                />
              </div>

              {/* Save Buttons */}
              <div className="knowledgeadd__buttons">
                <button
                  className="knowledgeadd__cancel"
                  onClick={() => navigate("/knowledgebase")}
                >
                  Cancel
                </button>
                {/* <button className="knowledgeadd__draft">Save to Draft</button> */}
                <button className="knowledgeadd__submit" disabled={loading}>
                  {loading ? "Saving..." : "Save & Submit"}
                </button>
              </div>
              <p> </p>
            </div>
          </div>
        )}
      </form>
      {/* Success Dialog */}
      {showSuccessDialog && (
        <div className="knowledgeadd__success-dialog">
          <p>Property updated successfully!</p>
          <button onClick={() => navigate("/knowledgebase")}>OK</button>
        </div>
      )}{" "}
    </div>
  );
};

export default KnowledgeDetail;
